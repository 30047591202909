<template>
  <skeleton-general-information-account-component v-if="bSkeleton" />
  <div v-else>
    <div class="content-general-information">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div v-if="!bModify">
              <p class="text-title">Nombre de la cuenta</p>
              <p v-if="oItemOrigin.sName" class="text-information">
                {{ oItemOrigin.sName }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-text-field v-else v-model="sName" label="Nombre de la cuenta" placeholder="Nombre de la cuenta..."
              class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined @keyup="validateFormMet()">
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div v-if="!bModify">
              <p class="text-title">Tipo de cuenta</p>
              <p v-if="oAccountTypeOrigin.sName" class="text-information">
                {{ oAccountTypeOrigin.sName }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-select v-else v-model="sAccountType" :items="itemAccountType" item-text="sName"
              item-value="sAccountTypeId" class="global-select field-code-country"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined
              @change="validateFormMet()">
              <template slot="label">
                <span>Tipo de cuenta<span class="important-data"></span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div v-if="!bModify">
              <p class="text-title">Moneda asociada</p>
              <p v-if="oCurrencyOrigin.sCurrencyName" class="text-information">
                {{ oCurrencyOrigin.sCurrencyName }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-select v-else v-model="sAssociatedCurrency" :items="itemAssociatedCurrency" readonly disabled item-text="sName"
              item-value="sCurrencyId" class="global-select field-code-country"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined
              @change="validateFormMet()">
              <template slot="label">
                <span>Moneda asociada<span class="important-data"></span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div v-if="!bModify">
              <p class="text-title">Nombre de la institución</p>
              <p v-if="oFinancialInstitutionOrigin.sName" class="text-information">
                {{ oFinancialInstitutionOrigin.sName }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-select v-else v-model="sNameInstitution" :items="itemNameInstitution" item-text="sName"
              item-value="sFinancialInstitutionId" class="global-select field-code-country"
              color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)" outlined
              @change="validateFormMet()">
              <template slot="label">
                <span>Nombre de la institución<span class="important-data"></span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4" xl="4">
            <div v-if="!bModify">
              <p class="text-title">Fecha de creación</p>
              <p v-if="oItemOrigin.tCreatedAt" class="text-information">
                {{ oItemOrigin.tCreatedAt }}
              </p>
              <p v-else class="text-data-empty">
                {{ emptyDataAddress }}
              </p>
            </div>
            <v-text-field v-else v-model="sCreateAt" disabled label="Fecha de creación"
              placeholder="Fecha de creación..." class="global-text-field" color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)" persistent-placeholder outlined @keyup="validateFormMet()">
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider class="divider-global mt-9"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-delete">
            <v-btn v-if="bModify" @click="deleteItem(oItemOrigin)" class="global-btn-second-red">
              Eliminar
            </v-btn>
            <v-btn v-if="!bModify" @click="goViewAccount()" class="global-btn-neutral">
              Regresar
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <div class="content-btn-discard" v-show="bAdminAccount">
            <v-btn v-if="bModify" v-show="!bEdit" class="global-btn-neutral" @click="discardChange()">
              Descartar cambios
            </v-btn>
          </div>
          <div v-if="bModify" v-show="bEdit" class="content-btn-cancel">
            <v-btn @click="bModify = !bModify" v-show="bAdminAccount" class="global-btn-neutral">Cancelar</v-btn>
          </div>
          <div v-if="!bModify" v-show="bAdminAccount" class="content-btn-modify">
            <v-btn class="global-btn-primary" @click="bModify = !bModify">Modificar</v-btn>
          </div>
          <div class="content-btn-update" v-show="!bEdit" v-else>
            <v-btn :loading="bLoadingBtnUpdate" v-show="bAdminAccount" class="global-btn-primary" @click="changeAccount()">Actualizar</v-btn>
          </div>
        </div>
      </v-container>
    </div>
    <delete-component-global @setDialogDelete="deleteItem" :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete" />
  </div>
</template>
<script>
export default {
  props: {
    iTab: Number,
  },
  data() {
    return {
      bModify: false,
      bEdit: true,
      bSkeleton: true,
      emptyDataAddress: "Sin información.",
      sName: "",
      sAccountType: null,
      itemAccountType: [],
      sAssociatedCurrency: "",
      itemAssociatedCurrency: [],
      sNameInstitution: null,
      itemNameInstitution: [],
      oItemOrigin: {},
      oAccountTypeOrigin: {},
      oFinancialInstitutionOrigin: {},
      oCurrencyOrigin: {},
      sCreateAt: "",
      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: true,
      },
      bLoadingBtnUpdate: false
    };
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
  },
  beforeMount() {
    // this.getGeneralInformation();
    this.$store
      .dispatch("getPermissionsByUserGlobal")
      .then((resp) => {
        this.getAccountType();
        this.getGeneralInformation();
      })
      .catch((err) => {
        this.Error(err);
      });
  },
  methods: {
    deleteItem(item) {
      if (item) {
        this.oDialogDelete.textTitle = "Eliminar cuenta";
        this.oDialogDelete.textDescription =
          "La eliminación de una cuenta es una acción irreversible.";
        this.oDialogDelete.textQuestion =
          "Acepto la responsabilidad y deseo proseguir con la eliminación de la cuenta: " +
          item.sName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/accounts/${this.$route.params.id}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    discardChange() {
      this.sName = this.oItemOrigin.sName;
      this.sAssociatedCurrency = this.oCurrencyOrigin.sCurrencyId;
      this.sNameInstitution =
        this.oFinancialInstitutionOrigin.sFinancialInstitutionId;
      this.sAccountType = this.oAccountTypeOrigin.sAccountTypeId;
      this.validateFormMet();
    },
    getGeneralInformation() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.get(
        `${URI}/api/sp/v1/accounts/${this.$route.params.id}`,
        config,
        payload
      )
        .then((response) => {
          this.bModify = false;
          this.oItemOrigin = response.data.results;
          this.setAccountBalanc(response.data.results.dAccountBalance);
          this.setCurrencyType(response.data.results.oCurrency.sCurrencyCode)

          this.$store.commit("setsNameDetail", this.oItemOrigin.sName);
          this.oAccountTypeOrigin = response.data.results.oAccountType;
          this.oFinancialInstitutionOrigin =
            response.data.results.oFinancialInstitution;
          this.oCurrencyOrigin = response.data.results.oCurrency;

          this.sName = response.data.results.sName;

          this.sAssociatedCurrency =
            response.data.results.oCurrency.sCurrencyId;

          this.sNameInstitution =
            response.data.results.oFinancialInstitution.sFinancialInstitutionId;

          this.sAccountType = response.data.results.oAccountType.sAccountTypeId;

          this.sCreateAt = response.data.results.tCreatedAt;

          this.bEdit = true;
          this.bSkeleton = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    validateFormMet() {
      this.bEdit =
        (this.sName.trim() === "" ||
          this.sName.trim() == this.oItemOrigin.sName.trim()) &&
        this.sAccountType === this.oAccountTypeOrigin.sAccountTypeId &&
        this.sAssociatedCurrency === this.oCurrencyOrigin.sCurrencyId &&
        this.sNameInstitution ===
        this.oFinancialInstitutionOrigin.sFinancialInstitutionId;
    },
    goViewAccount() {
      this.$router.push(`/home/account`).catch(() => { });
    },
    getAccountType() {
      DB.get(`${URI}/api/sp/v1/account-types`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.itemAccountType = response.data.results;
          this.itemAccountType.unshift({
            sName: "Seleccionar tipo de cuenta...",
            sAccountTypeId: null,
            disabled: true,
          });
          this.getAssociatedCurrency();
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    getAssociatedCurrency() {
      DB.get(`${URI}/api/sp/v1/currencies`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.itemAssociatedCurrency = response.data.results;
          this.itemAssociatedCurrency.unshift({
            sName: "Seleccionar moneda asociada...",
            sCurrencyId: null,
            disabled: true,
          });
          this.getInstitutionName();
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    getInstitutionName() {
      DB.get(`${URI}/api/sp/v1/financial-institutions`, {
        params: {},
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.itemNameInstitution = response.data.results;
          this.itemNameInstitution.unshift({
            sName: "Seleccionar institución",
            sFinancialInstitutionId: null,
            disabled: true,
          });
          this.getGeneralInformation();
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    changeAccount() {
      this.bLoadingBtnUpdate = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminAccount) {
            this.updateAccount();
          } else {
            this.Error(this.$store.state.oError403);
            this.discardChange();
            this.bLoadingBtnUpdate = false;

          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    updateAccount() {
      const payload = {
        sName: this.sName,
        sAccountTypeId: this.sAccountType,
        sFinancialInstitutionId: this.sNameInstitution,
        sCurrencyId: this.sAssociatedCurrency,
      },
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.put(
        `${URI}/api/sp/v1/accounts/${this.$route.params.id}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoadingBtnUpdate = false;

          this.Success(response.data.message);
          this.getGeneralInformation();
        })
        .catch((error) => {
          this.bLoadingBtnUpdate = false;

          this.Error(error.response.data);
        });
    },
    setAccountBalanc(dAccountBalance) {
      this.$emit("setAccountBalanc", dAccountBalance);
    },
    setCurrencyType(sCurrencyType) {
      this.$emit("setCurrencyType", sCurrencyType)
    }
  },
  computed: {
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
  },
  watch: {
    iTab() {
      if (this.iTab === 0) {
        this.$store
          .dispatch("getPermissionsByUserGlobal")
          .then((resp) => {
            this.getAccountType();
            this.getGeneralInformation();
          })
          .catch((err) => {
            this.Error(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.content-general-information {
  display: flex;
  width: 100%;
}

.content-btns-accions {
  display: flex;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  justify-content: flex-end;
  place-self: end;
}

.content-btn-modify {
  width: 200px;
  margin-left: 15px;
}

.content-btn-update {
  width: 200px;
  margin-left: 15px;
}

.content-btn-delete {
  width: 200px;
}

.content-btn-cancel {
  width: 200px;
}

.p-text-title {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  letter-spacing: 4.8px;
  opacity: 1;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.content-information {
  align-items: center;
  display: flex;
}

.p-title {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 14px;
}

.p-text {
  color: var(--primary-color-text);
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: "pop-Regular";
  font-size: 14px;
}

.text-color {
  /* -webkit-text-stroke: .5px black; */
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  width: 50%;
  border: solid var(--primary-color-text) 1px !important;
  /* margin-left: 10px; */
  font-family: "pop-Regular" !important;
  font-size: 14px !important;
}

.content-img {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  height: 100px;
  width: 100px;
}

.content-img img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-img img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.content-btn {
  width: 170px;
  align-self: center;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information {
    display: block;
    width: 100%;
  }

  .centered-image {
    display: flex;
    justify-content: center !important;
    text-align-last: center !important;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-information {
    margin-bottom: 10px;
  }

  .p-text-title {
    text-align: center;
  }

  .p-title {
    text-align: center;
  }

  .p-text {
    text-align: center;
    margin-left: 0px;
  }

  .content-color {
    text-align: -webkit-center;
  }

  .content-btn {
    width: 100%;
  }

  .content-btns-accions {
    display: block;
    width: 100%;
    margin-top: 25px;
    justify-content: flex-end;
    place-self: end;
  }

  .content-btn-modify {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
  }

  .content-btn-update {
    width: 100%;
    margin-left: 0px;
    margin-top: 15px;
  }

  .content-btn-delete {
    width: 100%;
    margin-top: 15px;
  }

  .content-btn-cancel {
    width: 100%;
    margin-top: 15px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-btns-accions {
    display: flex;
    width: 100%;
    margin-top: 25px;
    justify-content: flex-end;
    place-self: end;
  }

  .content-btn-modify {
    width: 140px;
    margin-left: 15px;
  }

  .content-btn-update {
    width: 140px;
    margin-left: 15px;
  }

  .content-btn-delete {
    width: 140px;
  }

  .content-btn-cancel {
    width: 140px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>